import { graphql } from 'gatsby';
import React from 'react';

import { TeslaLayout, TeslaSlice } from 'components';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const TeslaPage = ({ location, data }) => {
  const { prismicTeslaPage } = data;
  const { data: pageData, uid } = prismicTeslaPage;

  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    ogImage,
    schema,
    navigation_cta_link: ctaLink,
    navigation_cta_text: ctaText,
  } = pageData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
    schema: schema.text,
  };

  const navLink = { url: ctaLink?.url, text: ctaText?.text };

  return (
    <TeslaLayout location={location} seo={seo} uid={uid} navLink={navLink}>
      {sliceData.map((slice) => (
        <TeslaSlice key={slice.id} data={slice} location={location} />
      ))}
    </TeslaLayout>
  );
};

export default withPrismicPreview(TeslaPage);

export const teslaPageQuery = graphql`
  query TeslaPageBySlug($uid: String!) {
    prismicTeslaPage(uid: { eq: $uid }) {
      _previewable
      id
      uid
      prismicId
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        ogImage: open_graph_image {
          url
        }
        schema {
          text
        }
        navigation_cta_link {
          url
        }
        navigation_cta_text {
          html
          text
        }
        body {
          ...TeslaHeaderFragment
          ...TeslaFAQAccordionFragment
          ...TeslaComparisonWithCtaFragment
          ...TeslaStepByStepWithCtaFragment
          ...TeslaTwoColumnWithImageFragment
          ...TeslaComparisonTableWithCtaFragment
          ...TeslaContactSectionFragment
        }
      }
    }
  }
`;
